@import "creative";

a.whatsapp {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;

  .fa-whatsapp {
    margin-top: 16px;
  }
}
